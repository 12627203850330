import React, { useEffect, useState } from "react";
import axios from "axios";
import { url } from "../App";
import Loader from "./loader";
import { disableScroll, enableScroll } from "./request_form";

const Certificates = () => {
    const [fetching, setFetching] = useState(true)
    const [data, setData] = useState([])
    const [currentImg, setCurrentImg] = useState([])
    const sel = document.getElementById("cert_sort")
    let text
    let visible = false

    const originalReqImgs = require.context ( './certs_original', true, /\.(png|jpe?g|svg)$/ )
    const originalImageDict = Object.fromEntries( originalReqImgs.keys().map( image => [image.slice(2), originalReqImgs(image)]) )
    // const smallReqImgs = require.context ( './certs_small', true, /\.(png|jpe?g|svg)$/ )
    // const smallImageDict = Object.fromEntries( smallReqImgs.keys().map( image => [image.slice(2), smallReqImgs(image)]) )

    const sort_func = {
        "organization_a": function organization_a() {
            let ret = data.sort(function (a, b) {
                if (a.organization.toLowerCase() > b.organization.toLowerCase()) {
                    return 1
                  }
                if (a.organization.toLowerCase() < b.organization.toLowerCase()) {
                    return -1
                  }
                return 0
            })
            setData(ret)
        }, 
        "organization_d": function organization_d() {
            let ret = data.sort(function (a, b) {
                if (a.organization.toLowerCase() > b.organization.toLowerCase()) {
                    return -1
                  }
                if (a.organization.toLowerCase() < b.organization.toLowerCase()) {
                    return 1
                  }
                return 0
            })
            setData(ret)
        }, 
        "title_a": function title_a() {
            let ret = data.sort(function (a, b) {
                if (a.title.toLowerCase() > b.title.toLowerCase()) {
                    return 1
                  }
                if (a.title.toLowerCase() < b.title.toLowerCase()) {
                    return -1
                  }
                return 0
            })
            setData(ret)
        }, 
        "title_d": function title_d() {
            let ret = data.sort(function (a, b) {
                if (a.title.toLowerCase() > b.title.toLowerCase()) {
                    return -1
                  }
                if (a.title.toLowerCase() < b.title.toLowerCase()) {
                    return 1
                  }
                return 0
            })
            setData(ret)
        }, 
        "start_date_a": function start_date_a() {
            let ret = data.sort(function (a, b) {
                return new Date(a.start_date) - new Date(b.start_date)
            })
            setData(ret)
        }, 
        "start_date_d": function start_date_d() {
            let ret = data.sort(function (a, b) {
                return new Date(b.start_date) - new Date(a.start_date)
            })
            setData(ret)
        }, 
        "end_date_a": function end_date_a() {
            let ret = data.sort(function (a, b) {
                return new Date(a.end_date) - new Date(b.end_date)
            })
            setData(ret)
        }, 
        "end_date_d": function end_date_d() {
            let ret = data.sort(function (a, b) {
                return new Date(b.end_date) - new Date(a.end_date)
            })
            setData(ret)
        }
    }

    window.addEventListener('click', function(e){   
        if (document.documentElement.clientWidth<750)
        if (visible) {
                document.getElementsByClassName('shadow')[3].classList.add("hidden")
                enableScroll()
                visible = false
            } else if (document.getElementsByClassName('shadow')[3].classList.length === 1) visible = true
        }
    )

    function change_img(el) {
        setCurrentImg(el.file)
    }

    function unwrap_img(el) {
        change_img(el)
        disableScroll()

        document.getElementsByClassName('shadow')[3].classList.remove("hidden")
        document.getElementsByClassName('shadow')[3].style['marginTop'] = window.pageYOffset + "px"
        
    }

    useEffect(() => {
        sel?.addEventListener("change", function() {
            sort_func[this.value]()
            setCurrentImg(data[0].file)
          });
    })

    useEffect(() => {
        if (fetching) {
            if (sel === null || sel === undefined) text = null
            else text = sel.options[sel.selectedIndex].text

            axios.get(url + '/certs', {
            param: {
                soring_type: text
            }}
                    )
                    .then(response => {
                            setData(response.data.data)
                            setCurrentImg(response.data.data[0].file)
                            setFetching(false)
                        })  
                    .catch(err => {
                        console.log(err)
                })
        }
    }, [fetching])


    if (!fetching) {
        if (document.documentElement.clientWidth>=750) 
            return (
                <div className="cert-container">
                    <h3>Квалификация</h3>
                    <div className="row">
                        <p>Сортировать по:</p>
                        <select name="cert_sort" id="cert_sort">
                            <option value="organization_a" selected>Организации от А до Я</option>
                            <option value="organization_d">Организации от Я до А</option>
                            <option value="title_a">Названию от А до Я</option>
                            <option value="title_d">Названию от Я до А</option>
                            <option value="start_date_a">Дате начала от старых к молодым</option>
                            <option value="start_date_d">Дате начала от молодых к старым</option>
                            <option value="end_date_a">Дате окончания от старых к молодым</option>
                            <option value="end_date_d">Дате окончания от молодых к старым</option>
                        </select>
                </div>
                <p style={{marginBottom: "0"}}>Количество сертификатов: {data.length}</p>
                    {data.length > 0
                    ? <div className="row">
                        <div className="cert-scroll-wrapper">
                            <div className="cert-scroll">
                                {data.map(el => <button onClick={() => {change_img(el)}}>
                                    <p style={{marginTop: "1vh"}}>{el.organization}</p>
                                    <hr style={{borderColor: "black", width: "20vw"}}/>
                                    <p style={{marginBottom: "1vh"}}>{el.title}</p>
                                    <div style={{float: "right"}}>
                                        {el.start_date !== null
                                        ? <p style={{width: "fit-content"}}>Дата начала: {el.start_date}</p>
                                        : null}
                                        {el.end_date !== null
                                        ? <p style={{width: "fit-content", marginBottom: "1vh"}}>Дата окончания: {el.end_date}</p>
                                        : null}
                                    </div>
                                </button>)}
                        </div>
                        </div>
                    <div className="original-cert-img-wrapper">
                            <div style={{height: "59vh", alignItems: "center", display: "flex", margin: "auto"}}>
                                {currentImg.length > 0
                                ? <img src={originalImageDict[currentImg]} alt="" className="original-cert-img"/>
                                : null }
                            </div>
                        </div>
                    </div>
                    : null
                    }
                    
                </div>
            )
        else return (
            <div className="cert-container">
                    <h3>Квалификация</h3>
                    <div className="row">
                        <p>Сортировать по:</p>
                        <select name="cert_sort" id="cert_sort">
                            <option value="organization_a" selected>Организации от А до Я</option>
                            <option value="organization_d">Организации от Я до А</option>
                            <option value="title_a">Названию от А до Я</option>
                            <option value="title_d">Названию от Я до А</option>
                            <option value="start_date_a">Дате начала от старых к молодым</option>
                            <option value="start_date_d">Дате начала от молодых к старым</option>
                            <option value="end_date_a">Дате окончания от старых к молодым</option>
                            <option value="end_date_d">Дате окончания от молодых к старым</option>
                        </select>
                </div>
                <p style={{marginBottom: "0"}}>Количество сертификатов: {data.length}</p>
                    {data.length > 0
                    ? <div className="row">
                        <div className="cert-scroll-wrapper">
                            <div className="cert-scroll">
                                {data.map(el => <button onClick={() => {{unwrap_img(el)}}}>
                                    <p style={{marginTop: "1vh"}}>{el.organization}</p>
                                    <hr style={{borderColor: "black", width: "20vw"}}/>
                                    <p style={{marginBottom: "1vh"}}>{el.title}</p>
                                    <div style={{float: "right"}}>
                                        {el.start_date !== null
                                        ? <p style={{width: "fit-content"}}>Дата начала: {el.start_date}</p>
                                        : null}
                                        {el.end_date !== null
                                        ? <p style={{width: "fit-content", marginBottom: "1vh"}}>Дата окончания: {el.end_date}</p>
                                        : null}
                                    </div>
                                </button>)}
                            </div>
                        </div>
                    </div>
                    : null
                    }
                <div className="shadow hidden">
                    <div style={{margin: "auto"}}>
                        {currentImg.length > 0
                        ? <img src={originalImageDict[currentImg]} alt="" className="original-cert-img"/>
                        : null}
                    </div>
                </div>
                </div>
        )
    } else  return (
        <div className="cert-container">
            <h3>Квалификация</h3>
            <div className="row">
                <p>Сортировать по:</p>
                <select name="cert_sort" id="cert_sort">
                    <option value="organization_a" selected>Организации от А до Я</option>
                    <option value="organization_d">Организации от Я до А</option>
                    <option value="title_a">Названию от А до Я</option>
                    <option value="title_d">Названию от Я до А</option>
                    <option value="start_date_a">Дате начала от старых к молодым</option>
                    <option value="start_date_d">Дате начала от молодых к старым</option>
                    <option value="end_date_a">Дате окончания от старых к молодым</option>
                    <option value="end_date_d">Дате окончания от молодых к старым</option>
                </select>
            </div>
            <Loader/>
        </div>
    )
}

export default Certificates