import { useEffect, useState } from "react"
import {Request_Form, disableScroll} from "./request_form"


const Mobile_Intro = () => {
    return (
        <div>
            <div className="block-1" style={{backgroundPosition:"0 10vh", height:"100vh"}}>
                <div className="subblock-1">
                    <h2 style={{paddingTop: "20vh"}} className="white-text">Психолог и психотерапевт с 20-летним опытом - Галина Пушкова</h2>
                    <h4 className="white-text small-text">Все проблемы решаемы.
                    Работаю с детьми, подростками и взрослыми. </h4>
                    <h4 className="white-text small-text">Образование: высшее педагогическое.</h4>
                    <button className="btn-1" onClick={() => {disableScroll()
                        window.scrollTo({
                            top: 0,
                            left: 0,
                            behavior: 'smooth'
                          })
                        document.getElementsByClassName('shadow')[0].classList.remove("hidden");}}>Записаться на консультацию</button>
                </div>
            </div>
            <Request_Form/>
        </div>
    )
}

export default Mobile_Intro