import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
// import Favicon from 'react-favicon';
// import Icon from '../public/favicon.ico'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <>
    {/* <Favicon url="../public/favicon.ico"/> */}
        <App />

    </>
);

