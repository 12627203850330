import ReactLoading from "react-loading"
import React from "react";

const Loader = () => {
    return (
        <div className="shadow hidden"  id="loader"  style={{zIndex:'4'}}>
            <div className="loader">
                <ReactLoading type="spinningBubbles" color="#FA976C" width={100}/>
            </div>
        </div>
        
    )
}

export default Loader