import React from "react";
import {disableScroll} from "./request_form"

const Consulting_Type = () => {
    let style
    if (document.documentElement.clientWidth < 750) style = {marginTop: "2vh"}
    else style = {margin: "2vh", marginBottom: "3vh"}
    return (
        <div>
            <h3 className="black-text">Типы консультаций</h3>
            <div className="row">
                <div className="card">
                    <div style={style}>
                        <p>Детский, дошкольный и подрастковый психолог</p>
                    </div>
                    <button className="btn-2" onClick={() => {
                        disableScroll()
                        window.scrollTo({
                            top: 0,
                            left: 0,
                            behavior: 'smooth'
                          })
                        document.getElementsByClassName('shadow')[0].classList.remove("hidden");
                }}>Записаться</button>
                </div>
                <div className="card">
                    <div style={style}>
                        <p>Психотерапевт</p>
                    </div>
                    <button className="btn-2" onClick={() => {
                        disableScroll()
                        window.scrollTo({
                            top: 0,
                            left: 0,
                            behavior: 'smooth'
                          })
                        document.getElementsByClassName('shadow')[0].classList.remove("hidden");
                }}>Записаться</button>
                </div>
                <div className="card">
                    <div style={style}>
                        <p>Социальный психолог</p>
                    </div>
                    <button className="btn-2" onClick={() => {
                        disableScroll()
                        window.scrollTo({
                            top: 0,
                            left: 0,
                            behavior: 'smooth'
                          })
                        document.getElementsByClassName('shadow')[0].classList.remove("hidden");
                }}>Записаться</button>
                </div>
                <div className="card">
                    <div style={style}>
                        <p>Поиск решения проблем взаимоотношений</p>
                    </div>
                    <button className="btn-2" onClick={() => {
                        disableScroll()
                        window.scrollTo({
                            top: 0,
                            left: 0,
                            behavior: 'smooth'
                          })
                        document.getElementsByClassName('shadow')[0].classList.remove("hidden");
                }}>Записаться</button>
                </div>
            </div>

        </div>
    )
}

export default Consulting_Type