import { useEffect, useState } from "react"
import axios from "axios"
import { url } from "../App"
import Loader from "./loader"
import Close from "./1.svg"

function preventDefault(e) {
    e.preventDefault();
  }
function preventDefaultForScrollKeys(e) {
    var keys = {37: 1, 38: 1, 39: 1, 40: 1};
    if (keys[e.keyCode]) {
        preventDefault(e);
        return false;
    }
}
var supportsPassive = false;
try {
window.addEventListener("test", null, Object.defineProperty({}, 'passive', {
    get: function () { supportsPassive = true; }
}));
} catch(e) {}
var wheelOpt = supportsPassive ? { passive: false } : false;
var wheelEvent = 'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel';
// call this to Disable
export function disableScroll() {
    window.addEventListener('DOMMouseScroll', preventDefault, false); // older FF
    window.addEventListener(wheelEvent, preventDefault, wheelOpt); // modern desktop
    window.addEventListener('touchmove', preventDefault, wheelOpt); // mobile
    window.addEventListener('keydown', preventDefaultForScrollKeys, false);
}
// call this to Enable
export function enableScroll() {
    window.removeEventListener('DOMMouseScroll', preventDefault, false);
    window.removeEventListener(wheelEvent, preventDefault, wheelOpt);
    window.removeEventListener('touchmove', preventDefault, wheelOpt);
    window.removeEventListener('keydown', preventDefaultForScrollKeys, false);
}

export const Request_Form = () => {
    const [init, setInit] = useState(true)
    let visible1 = false
    let visible2 = false
    let folden = false

    function send_request() {
        if (document.getElementById('request-name').value.length === 0) {
            alert('Поле "Ваше имя" не должно быть пустым')
            return
        }
        
        if (document.getElementById('request-type').value.length === 0) {
            alert('Поле "Тип консультации" не должно быть пустым')
            return
        }
        
        if (document.getElementById('request-contact').value.length === 0) {
            alert('Поле "Контактная информация" не должно быть пустым')
            return
        }
        
        if (document.getElementById('request-info').value.length === 0) {
            alert('Поле "Опишите Вашу проблему" не должно быть пустым')
            return
        }

        if (document.getElementById('policy').checked === false) {
            alert("По закону мы не можем обрабатывать Ваши личные данные без Вашего согласия. Для продолжения взаимодействия необходимо принять политику конфиденциальности.")
            return
        }
        const request_name = document.getElementById('request-name').value
        const request_type = document.getElementById('request-type').value
        const request_contact = document.getElementById('request-contact').value
        const request_date = document.getElementById('request-date').value || null
        const request_information = document.getElementById('request-info').value
        
        document.getElementById('loader').classList.remove('hidden')
        axios.post(url + '/request'
            , {
                requestName: request_name,
                requestType: request_type,
                requestContact: request_contact,
                requestDate: request_date,
                requestInformation: request_information,
            }
            )
            .then(response => {
                document.getElementById('loader').classList.add('hidden')
                if (response.data.status === 'ok') alert('Отлично, Ваша заявка отправлена!')
                if (response.data.status === 'error') alert('К сожалению, произошла ошибка - ' + response.data.message + '\nВам необходимо связаться по личному номеру телефона, либо через социальные сети.')
                })
            .catch(err => {
                console.log(err)
            })
    }

    function send_problem() {
        if (document.getElementById('problem-name').value.length === 0) {
            alert('Поле "Ваше имя" не должно быть пустым')
            return
        }
        
        if (document.getElementById('problem-info').value.length === 0) {
            alert('Поле "Опишите проблему" не должно быть пустым')
            return
        }

        const problem_name = document.getElementById('problem-name').value
        const problem_information = document.getElementById('problem-info').value
        axios.post(url + '/problem'
            , {
                problemName: problem_name,
                problemInformation: problem_information,
            }
            )
            .then(response => {
                if (response.data.status === 'ok') alert('Спасибо, Ваше сообщение отправлено!')
                if (response.data.status === 'error') alert('К сожалению, произошла ошибка - ' + response.data.message + '\nВам необходимо связаться по личному номеру телефона, либо через социальные сети.')
                })
            .catch(err => {
                console.log(err)
            })
    }

    window.addEventListener('mouseover', function(e){
            const rect = document.getElementById('request-type').getBoundingClientRect()
            const width = rect['width']
            const top = rect['top'] + rect["height"] / 2
            const left = rect['left']
            document.getElementsByClassName('dropdown-checkbox')[0].children[1].style.cssText = 'width: ' + width + 'px; top: ' + top + 'px; left: ' + left + 'px;'
        }
    )  
    
    window.addEventListener('click', function(e){   
        if (visible1) {
            if ((!document.getElementsByClassName('central')[0].contains(e.target)) && (document.getElementsByClassName('shadow')[0].classList.length === 1)) {
                        document.getElementsByClassName('shadow')[0].classList.add("hidden")
                        enableScroll()
                        visible1 = false
                    }
            } else if (document.getElementsByClassName('shadow')[0].classList.length === 1) visible1 = true
        
            if (visible2) {
            if ((!document.getElementsByClassName('central')[1].contains(e.target)) && (document.getElementsByClassName('shadow')[2].classList.length === 1)) {
                        document.getElementsByClassName('shadow')[2].classList.add("hidden")
                        enableScroll()
                        visible2 = false
                    }
            } else if (document.getElementsByClassName('shadow')[2].classList.length === 1) visible2 = true
        }
    )
    
    useEffect(() => {
        const checkboxes = document.querySelectorAll("input[type=checkbox]")
        checkboxes.forEach((checkbox) => {
            checkbox.addEventListener('change', function(e){
                let string = ''
                checkboxes.forEach((ch) => {
                    if (ch.checked && ch.value !== 'on') {
                        string += ch.value + ' / '
                    }
                })
                string = string.slice(0, -3)
                document.getElementById('request-type').value = string
            })
        })
    })

    return (
        <div>
        <div className="shadow hidden">
            <div className="central">
                <div className="row">
                    <div>
                        <p className="input-text">Ваше имя<span style={{color: "red"}}>*</span></p>
                        <input type="text" className="input" id="request-name"/>
                    </div>
                    <div>
                        <p className="input-text">Тип консультации<span style={{color: "red"}}>*</span></p>
                        <div class="dropdown-checkbox form-group">
                            <input type="text" className="input label-title" id="request-type" disabled/>
                            <ul>
                                <li><label><input type="checkbox" value="Онлайн"/>Онлайн</label></li>
                                <hr />
                                <li><label><input type="checkbox" value="В офисе г.Сергиев Посад"/>В офисе г.Сергиев Посад</label></li>
                            </ul>
                        </div>
                    </div>
                    
                </div>
                <div className="row">
                    <div>
                        <p className="input-text">Контактная информация<span style={{color: "red"}}>*</span></p>
                        <input type="text" className="input"  placeholder="Почта/телефон/соц. сеть" id="request-contact"/>
                    </div>
                    <div>
                        <p className="input-text">Когда Вам удобно</p>
                        <input type="datetime-local" className="input" id="request-date"/>
                    </div>
                </div>
                <p className="input-text" style={{width: "33vw"}}>Опишите Вашу проблему<span style={{color: "red"}}>*</span></p>
                <textarea className="input big-input" id="request-info"/>
                <p  className="input-text"  style={{width: "33vw", height: "fit-content", marginBottom: "2vh"}}>Стоимость консультации - 4`000 руб.</p>
                <div className="policy row">
                    <input type="checkbox" name="policy" id="policy" className="policy-check"/>
                    <p className="policy-text">Принять <a href="/privacy">политику конфиденцальности</a> и дать разрешение на обработку персональных данных</p>
                </div>
                
                <button className="btn-1" onClick={() => send_request()}> Отправить заявку</button>
            </div>
            <div className="exit">
                <img src={Close} alt="" />
            </div>
            <Loader/>
        </div>
            <div className="shadow hidden">
                <div className="central">
                    <div>
                        <p className="input-text">Ваше имя<span style={{color: "red"}}>*</span></p>
                        <input type="text" className="input" id="problem-name"/>
                        <p className="input-text">Опишите проблему<span style={{color: "red"}}>*</span></p>
                        <textarea className="input big-input" id="problem-info"/>
                        <button className="btn-1" onClick={() => send_problem()}>Отправить</button>
                    </div>
                </div>
                <div className="exit">
                    <img src={Close} alt="" />
                </div>
            </div>
        </div>

    )
}

export default Request_Form