import React from "react";
import { disableScroll } from "./request_form";
import Image1 from "./4.png"

const Footer = () => {
    function click1() {
        disableScroll()
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
            })
        document.getElementsByClassName('shadow')[0].classList.remove("hidden");
    }
    function click2() {
        disableScroll()
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
            })
        document.getElementsByClassName('shadow')[2].classList.remove("hidden");
    }

    if (document.documentElement.clientWidth>=750) {
    return (
        <div className="footer">
            <div className="row">
                <img src={Image1} alt="" style={{width: "8vw", height: "auto", borderRadius: "50%", minWidth: "80px", minHeight: "80px"}}/>
                <div className="footer-column">
                    <a href="/" className="blank_link">На главную</a>
                    <a onClick={() => click1()} className="blank_link" style={{cursor: "pointer"}}>Записаться на консультацию</a>
                    <a href="/about" className="blank_link">Обо мне</a>

                </div>
                <div className="footer-column">
                    <a href="/privacy" className="blank_link">Политика конфиденциальности</a>
                    <a onClick={() => click2()} className="blank_link" style={{cursor: "pointer"}}>Поддержка сайта</a>
                </div>
            </div>
        </div>
    ) } else return (
        <div className="footer">
            <div className="footer-row">
                <img src={Image1} alt="" style={{width: "auto", height: "8vh", borderRadius: "10vw", minWidth: "80px", minHeight: "80px"}}/>
                <div className="footer-column">
                    <a href="/" className="blank_link">На главную</a>
                    <a onClick={() => click1()} className="blank_link" style={{cursor: "pointer"}}>Записаться на консультацию</a>
                    <a href="/about" className="blank_link">Обо мне</a>

                </div>
                <div className="footer-column">
                    <a href="/privacy" className="blank_link">Политика конфиденциальности</a>
                    <a onClick={() => click2()} className="blank_link" style={{cursor: "pointer"}}>Поддержка сайта</a>
                </div>
            </div>
        </div>
    )
}

export default Footer