import React, { useEffect, useState } from "react";
import YouTube from "react-youtube";

const Video = () => {
  const videoReady = (event) => {
      event.target.pauseVideo();
  }

  if (document.documentElement.clientWidth>=750) {
  const opts = {
    playerVars: { autoplay: 0 },
    width: "" + document.documentElement.clientWidth * 20 / 50 
    // width: "100%",
    // height: "100%"
  }
  return (
      <>
        <h1 style={{fontSize: "3vh"}}>Репортажи телевидения</h1>
        <div>
          <div className="row"
            style={{
              margin: "auto",
              marginTop: "12px",
              overflow: "hidden",
            }}
          >
              <div style={{paddingRight: "2vw"}}>
                      <YouTube
                  videoId="L9HJtFaozTU"
                  opts={opts}
                  onReady={videoReady}
              />
              </div>
              <div style={{paddingLeft: "2vw"}}>
                  <YouTube
                      videoId="FlzTJajMvrs"
                      opts={opts}
                      onReady={videoReady}
                  />
              </div>
            
          </div>
        </div>
      </>
    )
  } else {
    const opts = {
      playerVars: { autoplay: 0 },
      // height: "40vh"
      width: "" + document.documentElement.clientWidth * 40 / 50 
    }
    return (
      <>
        <h1 style={{fontSize: "3vh"}}>Репортажи телевидения</h1>
        <div>
          <div className="row"
            style={{
              margin: "auto",
              marginTop: "12px",
              overflow: "hidden",
              // width: "50vw",
              // height: "20vh"
            }}
          >
                      <YouTube
                  videoId="L9HJtFaozTU"
                  opts={opts}
                  onReady={videoReady}
              />
                  <YouTube
                      videoId="FlzTJajMvrs"
                      opts={opts}
                      onReady={videoReady}
                  />
            
          </div>
        </div>
      </>
    )
  }
}

export default Video