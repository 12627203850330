import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom'
import React from 'react'
import './App.css';
import Navbar from './components/navbar';
import Main from './components/main'
import Privacy from './components/privacy';
import About from './components/about';

// export const url = 'http://localhost:8000/v1';
// export const short_url = 'http://localhost:8000';
// export const short_3k_url = 'http://localhost:3000';

export const url = 'https://pushkova-galina.ru/v1';
export const short_url = 'https://pushkova-galina.ru';
export const short_3k_url = 'https://pushkova-galina.ru';

function App() {
  return (
    <div className="App">
       <BrowserRouter>
          <Navbar/>
            <Routes>
              <Route path='' element={<Main replace to=""/>} />
              {/* <Route path='/test' element={<Test/>} /> */}
              {/* <Route path='/request' element={<Request_Form_Page/>} /> */}
              <Route path='/privacy' element={<Privacy/>} />
              <Route path='/about' element={<About/>} />
            </Routes>
        </BrowserRouter>
    </div>
  );
}

export default App;
