import React, { useEffect, useState } from "react";
import axios from "axios";
import {url} from "../App"


const Articles = () => {
    const [articles, setArticles] = useState([])
    const [fetching, setFetching] = useState(true)

    useEffect(() => {
        if (fetching)
            axios.get(url + '/articles',
                )
                .then(response => {
                        setArticles(response.data.articles)
                        setFetching(false)
                    })  
                .catch(err => {
                    console.log(err)
                })
    }, [fetching])

    if (!fetching)
    return (
        <div className="block-6">
            <h3>
                Статьи на тему психологии
            </h3>
            <div className="subblock-6">
                <div className="subblock-6-scroll">
                    {articles.map(element => <button className="subblock-6-btn">
                        <a href={element.href} target="_blank">
                            <h4>
                                {element.title}
                            </h4>
                            <p><i>
                                {element.source}
                            </i></p>
                            <p>
                                {element.phrase}
                            </p>
                        </a>
                    </button>)}
                </div>
            </div>
        </div>
    )
}

export default Articles