import React from 'react';
import { disableScroll } from './request_form';

const Navbar = () => {
    function click() {
        disableScroll()
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
            })
        document.getElementsByClassName('shadow')[0].classList.remove("hidden");
    }
    if (document.documentElement.clientWidth>=750)
    return (
            <nav>
                <ul>
                    <li><a href="/">Главная страница</a></li>
                    <li><p onClick={() => click()}>Записаться на консультацию</p></li>
                    <li><a href="/about">Обо мне</a></li>
                </ul>
            </nav>
    )
    else
    return (
        <div class="menu">
            <input type="checkbox" id="burger-checkbox" class="burger-checkbox"/>
            <label for="burger-checkbox" class="burger"></label>
            <ul class="menu-list">
                <li><a href="/"  class="menu-item">Главная страница</a></li>
                <li><p class="menu-item" onClick={() => {click()}}>Записаться на консультацию</p></li>
                <li><a href="/about"  class="menu-item">Обо мне</a></li>
            </ul>
        </div>
    )
}
export default Navbar