import React, { useEffect, useState, useRef  } from "react";
import elementInViewport from './main'

const Situations = () => {
    const step1 = ["Стресс", "Тревога", "Беспокойство", "Смятение", "Неуверенность", "Волнение", "Растерянность",  "Паника", "Взволнованность", "Смущение", "Тоска", "Хандра", "Панические атаки", "Переходный возраст", "Кризис среднего возраста",  "Послеродовая депрессия"]
    const step2 = ["Развод", "Расторжение брака", "Помощь с социализацией", "Пищевые расстройства", "Булимия", "Анорексия", "Компульсивное переедание", "Чрезмерная опека", "Недостаток внимания", "Расстройствое пищевого поведения", "Истерия", "Горе"]
    const step3 = ["Помощь с трудными подростками", "Депрессия", "Меланхолия",  "Ипохондрия", "Удручённость", "Угнетённость", "Прострация", "Ступор", "Анабиоз", "Неврозы", "Неврастения", "Психоз", "Психастения", "Психоневроз", "Тревожность", "Мнительность"]
    const el1 = document.getElementsByClassName('floating_card_container')[0]
    const el2 = document.getElementsByClassName('floating_card_container')[1]
    const el3 = document.getElementsByClassName('floating_card_container')[2]
    const [list1, setList1] = useState([...step1, ...step1, ...step1])
    const [list2, setList2] = useState([...step2, ...step2, ...step2])
    const [list3, setList3] = useState([...step3, ...step3, ...step3])
    const lastElement1 = useRef()
    const lastElement2 = useRef()
    const lastElement3 = useRef()
    const firstElement1 = useRef()
    const firstElement2 = useRef()
    const firstElement3 = useRef()
    const observer1 = useRef()
    const observer2 = useRef()
    const observer3 = useRef()
    const observer4 = useRef()
    const observer5 = useRef()
    const observer6 = useRef()

    useEffect(() => {
        let y = 0
        let y1 = 0
        let y2 = 0
        let y3 = 0
        let tr
        const elements = Array.from(document.getElementsByClassName('floating_card_container'))
        
        // if (elements.length > 0)
        //     elements.map((element, index) => 
        //         element.addEventListener('wheel', (e) => {
        //             console.log(!document.getElementsByClassName('floating_card_container')[0].contains(e.target), !document.getElementsByClassName('floating_card')[0].contains(e.target))
        //                 e.preventDefault();
        //                 const height = document.body.offsetWidth
        //                 switch(index){
        //                     case 0:
        //                         // y = y + .8*e.wheelDeltaY
        //                         y = y + 2*e.wheelDeltaY
        //                     case 1:
        //                         // y = y + 1.1*e.wheelDeltaY
        //                         y = y + .2*e.wheelDeltaY
        //                     case 2:
        //                         // y = y + .1*e.wheelDeltaY
        //                         y = y + .2*e.wheelDeltaY
        //                 }
                        
        //                 if(y < -height + window.innerWidth) {
        //                     window.scrollBy(y, window.innerWidth)
        //                 }
        //                 // if(y > 0) {
        //                 //     y = 0
        //                 // }
        //                 tr = `translateX(${y}px)`
                        
        //                 for (let el of element.children) {
        //                     el.style.transform = tr
        //                 }
        //             }
                    
        //         )
        //     )

        
        var start_y = {y: 0}
        document.body.addEventListener("touchstart", function touchStart(event) {
            start_y.y = event.touches[0].pageY;
          })


        'wheel, touchmove'.split(', ').forEach(function(event_type) {
            document.body.addEventListener(event_type, (e) => {
                let delta
                if (event_type === 'wheel') {
                    delta = e.wheelDeltaY
                    y1 = y1 + delta
                    y2 = y2 + delta
                    y3 = y3 + delta
                } else if (event_type === 'touchmove') {
                    // console.log(start_y.y, e.touches[0].pageY)
                    if (start_y.y - e.touches[0].pageY > 0) delta = .035*e.touches[0].pageY
                    else delta = -.035*e.touches[0].pageY
                    y1 = y1 + delta
                    y2 = y2 + delta
                    y3 = y3 + delta
                }
                
                const el1 = document.getElementsByClassName('floating_card_container')[0]
                const el2 = document.getElementsByClassName('floating_card_container')[1]
                const el3 = document.getElementsByClassName('floating_card_container')[2]
                if (el1 !== undefined)
                    for (let element1 of el1.children) element1.style.transform = `translateX(${-y1}px)`
                if (el2 !== undefined)
                    for (let element2 of el2.children) element2.style.transform = `translateX(${-y2}px)`
                if (el3 !== undefined)
                    for (let element3 of el3.children) element3.style.transform = `translateX(${-y3}px)`
            }
        )
    })
    })
    useEffect(() => {
        if(observer1.current) observer1.current.disconnect()
        var callback = function (entries, observer) {
            if (entries[0].isIntersecting) {    
                setList1([...list1, ...step1])          
            }
        }
        observer1.current = new IntersectionObserver(callback)
        if (lastElement1.current !== undefined)
            observer1.current.observe(lastElement1.current)


        if(observer2.current) observer2.current.disconnect()
        var callback = function (entries, observer) {
            if (entries[0].isIntersecting) {    
                setList2([...list2, ...step2])          
            }
        }
        observer2.current = new IntersectionObserver(callback)
        if (lastElement1.current !== undefined)
            observer2.current.observe(lastElement2.current)


        if(observer3.current) observer3.current.disconnect()
        var callback = function (entries, observer) {
            if (entries[0].isIntersecting) {   
                setList3([...list3, ...step3])          
            }
        }
        observer3.current = new IntersectionObserver(callback)
        if (lastElement1.current !== undefined)
            observer3.current.observe(lastElement3.current)

        if(observer4.current) observer4.current.disconnect()
        var callback = function (entries, observer) {
            if (entries[0].isIntersecting) {    
                setList1([ ...step1, ...list1])          
            }
        }
        observer4.current = new IntersectionObserver(callback)
        if (firstElement1.current !== undefined)
            observer4.current.observe(firstElement1.current)

        if(observer5.current) observer5.current.disconnect()
        var callback = function (entries, observer) {
            if (entries[0].isIntersecting) {   
                setList2([ ...step2, ...list2])          
            }
        }
        observer5.current = new IntersectionObserver(callback)
        if (firstElement2.current !== undefined)
            observer5.current.observe(firstElement2.current)
        
        if(observer6.current) observer6.current.disconnect()
        var callback = function (entries, observer) {
            if (entries[0].isIntersecting) {  
                setList3([ ...step3, ...list3])          
            }
        }
        observer6.current = new IntersectionObserver(callback)
        if (firstElement3.current !== undefined)
            observer6.current.observe(firstElement3.current)
    })


    return (
        <div style={{marginBottom: "3vh"}}>
            <h3 className="black-text">Ситуации, с которыми я работаю</h3>
            <div className="floating_card_container" style={{borderRadius: "5vh 5vh 0 0", borderBottom: '0px'}}>
                <div ref={firstElement1} style={{width:"1px"}}></div>
                {list1.map(el => <div className="floating_card">
                        {el.length > 15
                        ? el.length >= 26
                            ? <p>{el}</p>
                            : <p style={{paddingTop: "1vh"}}>{el}</p>
                        : <p style={{paddingTop: "2vh"}}>{el}</p>
                        }
                    </div>
                    )
                }
                <div ref={lastElement1} style={{width:"1px"}}></div>
            </div>
            <div className="floating_card_container" style={{borderTop: '0px', borderBottom: '0px'}}>
                <div ref={firstElement2} style={{width:"1px"}}></div>
                {list2.map(el => <div className="floating_card">
                    {el.length > 15
                        ? el.length >= 26
                            ? <p>{el}</p>
                            : <p style={{paddingTop: "1vh"}}>{el}</p>
                        : <p style={{paddingTop: "2vh"}}>{el}</p>
                        }
                    </div>
                    )
                }
                <div ref={lastElement2} style={{width:"1px"}}></div>
            </div>
            <div className="floating_card_container" style={{borderRadius: "0 0 5vh 5vh", borderTop: '0px'}}>
                <div ref={firstElement3} style={{width:"1px"}}></div>
                {list3.map(el => <div className="floating_card">
                    {el.length > 15
                        ? el.length >= 26
                            ? <p>{el}</p>
                            : <p style={{paddingTop: "1vh"}}>{el}</p>
                        : <p style={{paddingTop: "2vh"}}>{el}</p>
                        }
                    </div>
                    )
                }
                <div ref={lastElement3} style={{width:"1px"}}></div>
            </div>
        </div>
    )
}

export default Situations