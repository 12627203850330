import React, { useEffect } from "react";
import Photo from "./2.jpg"
import Icon1 from "./2.svg"
import Icon2 from "./3.svg"
import Icon3 from "./4.svg"
import Icon4 from "./5.svg"
import Icon5 from "./6.svg"

const Citition = () => {
    useEffect(() => {
        let max_height = 0
        let header = []
        const elements = Array.from(document.getElementsByClassName('subblock-4'))
        const p_elements = Array.from(document.getElementsByClassName('subblock-4-p'))
        const vh = window.innerHeight / 100
        
        elements.map(element => {
            if (element.offsetHeight > max_height) max_height = element.offsetHeight
            header.push(element.firstChild.getBoundingClientRect()["height"])
        })
        elements.map(element => {
            // - 4.25*vh
            element.style["height"] = max_height - 3*vh + 'px'
        })
        const p_height = max_height - 2 * vh
        p_elements.map((element, index) => {
            element.style["paddingTop"] = (p_height - header[index] - element.getBoundingClientRect()['height']) / 2 + 'px'
        })
    })

    useEffect(() => {
        const block = document.getElementsByClassName('block-4')[0].getBoundingClientRect()
        const citition_photo = document.getElementsByClassName('citation-photo')[0].getBoundingClientRect()
        document.getElementsByClassName('citation-photo')[0].style['left'] = block['width'] / 2 - citition_photo['x'] - citition_photo['width'] / 2 + (window.innerWidth - document.getElementsByTagName('html')[0].clientWidth)/2 + 'px'
    })
    return (
        <div>
            <div className="block-4">
                <div className="citation">
                    <p>
                        Как говорил Альфред Адлер, имея конкретную цель, человек чувствует себя в состоянии преодолеть любые проблемы, так как в нём живет его будущий успех
                    </p>
                </div>
                <img src={Photo} alt="" className="citation-photo" />
            </div>
            
                <div className="row subblock-row-4">
                    <div className="subblock-4">
                        <div className="mobile-row row">
                            <img src={Icon1} alt="" className="photo-subblock-4" />
                            <h4>
                                Квалификация
                            </h4>
                        </div>
                        <p className="subblock-4-p">
                            Высшее образование, 20 курсов повышения квалификации и более 20 лет непрерывного опыта
                        </p>
                    </div>
                    <div className="subblock-4">
                        <div className="mobile-row row">
                            <img src={Icon2} alt="" className="photo-subblock-4" />
                            <h4>
                                Профессиональная терапия
                            </h4>
                        </div>
                        <p className="subblock-4-p">
                            Совмещение разнообразных техник и методик разных направлений в каждом конкретном случае для достижения результата.
                        </p>
                    </div>
                    <div className="subblock-4">
                        <div className="mobile-row row">
                            <img src={Icon3} alt="" className="photo-subblock-4" />
                            <h4>
                                Клиентская база 
                            </h4>
                        </div>
                        <p className="subblock-4-p">
                            Более +200 довольных клиентов, более +60 отзывов, средняя оценка - 5.0
                        </p>
                    </div>
                </div>
                <div className="row" style={{borderBottom: "1px solid #9c9c9c"}}>
                    <div className="subblock-4" style={{margingTop: 0}}>
                        <div className="mobile-row row">
                            <img src={Icon4} alt="" className="photo-subblock-4" />
                            <h4>
                                Эксперт и приглашенный гость местного телевидения
                            </h4>
                        </div>
                        <p className="subblock-4-p">
                            Делилась экспертным мнением по поводу детского воспитания и развития на канале ТВР24
                        </p>
                    </div>
                    <div className="subblock-4" style={{margingTop: 0}}>
                        <div className="mobile-row row">
                            <img src={Icon5} alt="" className="photo-subblock-4" />
                            <h4>
                                Проработала 20 лет в приюте психологом
                            </h4>
                        </div>
                        <p className="subblock-4-p">
                            В прошлом - социальный работник и психолог социально-реабилитационного центра для несовершеннолетних
                        </p>
                    </div>
                </div>
        </div>
    )
}

export default Citition