import React, { useState } from "react";
import { useEffect } from "react";
import axios from "axios"
import { url } from "../App";
import Star from "./star.svg"


const Reviews = () => {
    const [fetching, setFetching] = useState(false)
    const [arr1, setArr1] = useState([])
    const [arr2, setArr2] = useState([])
    const [arr3, setArr3] = useState([])

    function getRatingStars(rating) {
        let stars = []
        for (let i = 0; i < rating; i++) {
            stars.push(<img src={Star} alt="" className="stars" />)
        }
        return stars
    }
    useEffect(() => {
        if (!fetching)
        axios.get(url + '/reviews',
            )
            .then(response => {
                let flag = 1
                for ( let i = 0; i < response.data.reviews.length; i++) {
                    switch (flag) {
                        case 3:
                            {
                                setArr3(oldArray => [...oldArray, response.data.reviews[i]])
                                flag = 1
                                continue
                            }
                        case 2:
                            {
                                setArr2(oldArray => [...oldArray, response.data.reviews[i]])
                                flag = 3
                                continue
                            }
                        case 1:
                            {
                                setArr1(oldArray => [...oldArray, response.data.reviews[i]])
                                flag = 2
                                continue
                            }
                    }                
                }
                })
            .catch(err => {
                console.log(err)
            })
            .finally(() => {setFetching(true)})
        }, [fetching])

    if (fetching)
    return (
        <div className="reviews">
            <h3 style={{margin: '0', paddingTop: '2vh', fontSize: "3vh"}}>Отзывы клиентов</h3>
            <div className="reviews-container">
                <div className="reviews-block-scroll" style={{borderRadius: "5vh 0 0 5vh"}}>
                    { arr1.map(element => 
                        <div className="reviews-block">
                            <p style={{textAlign: "left"}}>
                                {element.name}
                            </p>
                            <p style={{textAlign: "left"}}>
                                {element.post_time}
                            </p>
                            <p>
                            {getRatingStars(element.rating)}
                            </p>
                            { element.comment !== null && element.comment.length > 0
                            ? <div>
                                <hr style={{width: "70%"}}/>
                                <p style={{textAlign: "justify"}}>
                                    {element.comment}
                                </p>
                            </div>
                            : null
                            }
                            
                            {element.answer !== null
                            ? <div>
                                <hr style={{width: "70%"}}/>
                                <p>Ответ психолога: </p>
                                <p style={{textAlign: "justify"}}>
                                    {element.answer}
                                </p>
                            </div>
                            : null}
                        </div>) 
                    }
                </div>
                <div className="reviews-block-scroll">
                    { arr2.map(element => 
                        <div className="reviews-block">
                            <p style={{textAlign: "left"}}>
                                {element.name}
                            </p>
                            <p style={{textAlign: "left"}}>
                                {element.post_time}
                            </p>
                            <p>
                            {getRatingStars(element.rating)}
                            </p>
                            { element.comment !== null && element.comment.length > 0
                            ? <div>
                                <hr style={{width: "70%"}}/>
                                <p style={{textAlign: "justify"}}>
                                    {element.comment}
                                </p>
                            </div>
                            : null
                            }
                            
                            {element.answer !== null
                            ? <div>
                                <hr style={{width: "70%"}}/>
                                <p>Ответ психолога: </p>
                                <p style={{textAlign: "justify"}}>
                                    {element.answer}
                                </p>
                            </div>
                            : null}
                        </div>) 
                    }
                </div>
                <div className="reviews-block-scroll" style={{borderRadius: "0 5vh 5vh 0"}}>
                    { arr3.map(element => 
                        <div className="reviews-block">
                            <p style={{textAlign: "left"}}>
                                {element.name}
                            </p>
                            <p style={{textAlign: "left"}}>
                                {element.post_time}
                            </p>
                            <p>
                            {getRatingStars(element.rating)}
                            </p>
                            { element.comment !== null && element.comment.length > 0
                            ? <div>
                                <hr style={{width: "70%"}}/>
                                <p style={{textAlign: "justify"}}>
                                    {element.comment}
                                </p>
                            </div>
                            : null
                            }
                            {element.answer !== null
                            ? <div>
                                <hr style={{width: "70%"}}/>
                                <p>Ответ психолога: </p>
                                <p style={{textAlign: "justify"}}>
                                    {element.answer}
                                </p>
                            </div>
                            : null}
                        </div>) 
                    }
                </div>
            </div>

        </div>
    )
}

export default Reviews