import React, { useEffect, useState } from "react";
import axios from "axios";
import { url } from "../App";

const Neuro = () => {
    const [neuro, setNeuro] = useState([])
    const [dataset, setDataset] = useState([])
    const [fetching, setFetching] = useState(true)
    useEffect(() => {
        if (fetching)
            axios.get(url + '/neuro',
                )
                .then(response => {
                        setNeuro(response.data.nn)
                        setDataset(response.data.dataset)
                        setFetching(false)
                    })  
                .catch(err => {
                    console.log(err)
            })
    }, [fetching])

    if (!fetching)
    return (
        <div className="neuro-container">
            <h3>Нейронные сети в психологии</h3>
            <p>Современный мир с трудом представляется без информационных технологий. В данный момент широкое распространение получили нейронные сети - математические модели, основанные на архитектуре и биологическиом строении нейронов головного мозга. Шагающее широкой поступью моделирование естественных процессов затрагивает большинство научных сфер, психология не является исключением.</p>
            <p>Далее представлены наиболее распространенные открытые модели и наборы данных для обучения (датасеты) в области психологии. Нейронные сети зачастую требуют большое количество вычислительных мощностей, поэтому они размещены в публичном репозитории и сообществе исследователей искусственного интеллекта - <a href="https://huggingface.co" style={{textDecoration: "none", color: "#D2FDFF"}}><b>HuggingFace</b></a>.</p>
            <p>Поэтому возможны разнообразные перебои в работе сервиса. Данное направление позволяет понять без затрат материальных средств, необходима ли Вам квалифицированная помощь. В любом случае, есть возможноть тонкой настройки предобученных огромных текстовых сетей (от англ. LLM - large language model) - <a href="https://huggingface.co/docs/transformers/v4.16.2/en/training" style={{textDecoration: "none", color: "#D2FDFF"}}><b>Инструкция</b></a></p>
            <div className="neuro-wrapper">
                <div className="neuro">
                    <h4>
                        Нейронные сети
                    </h4>                    
                    <h4>
                        Датасеты
                    </h4>
                </div>
                <hr style={{width: "80vw"}}/>
                <div className="neuro">
                    <div className="neuro-scroll" style={{borderRadius: "0 0 0 5vh"}}>
                        {neuro.map(element => 
                            <button className="subblock-6-btn" style={{width: "40vw"}}>
                                <a href={element.href} target="_blank">
                                    <h4 style={{width: "33vw"}}>
                                        {element.title}
                                    </h4>
                                    <p><i>
                                        {element.source}
                                    </i></p>
                                    <p>
                                        {element.phrase}
                                    </p>
                                </a>
                            </button>
                            )}
                    </div>
                    <hr style={{height: "25vh", marginTop: "5vh", marginLeft: "1vw", marginRight: "1vw"}}/>
                    <div className="neuro-scroll" style={{borderRadius: "0 0 5vh 0"}}>
                    {dataset.map(element => 
                        <button className="subblock-6-btn" style={{width: "40vw"}}>
                            <a href={element.href} target="_blank">
                                <h4 style={{width: "33vw"}}>
                                    {element.title}
                                </h4>
                                <p><i>
                                    {element.source}
                                </i></p>
                                <p>
                                    {element.phrase}
                                </p>
                            </a>
                        </button>
                        )}
                    </div>
                </div>


            </div>
        </div>
    )
}

export default Neuro