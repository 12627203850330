import Intro from "./intro"
import Consulting_Type from "./consulting"
import Situations from "./situations"
import Citition from "./citition"
import Reviews from "./reviews"
import Video from "./video"
import Certificates from "./certifitates"
import Articles from "./articles"
import Neuro from "./neuro"
import Footer from "./footer"
import {useLayoutEffect, useState} from 'react'
import Contacts from "./contacts"

export function elementInViewport(el) {
    var top = el.offsetTop;
    var left = el.offsetLeft;
    var width = el.offsetWidth;
    var height = el.offsetHeight;
    while(el.offsetParent) {
      el = el.offsetParent;
      top += el.offsetTop;
      left += el.offsetLeft;
    }
    return (
      top >= window.pageYOffset &&
      left >= window.pageXOffset &&
      (top + height) <= (window.pageYOffset + window.innerHeight) &&
      (left + width) <= (window.pageXOffset + window.innerWidth)
    );
  }

const Main = () => {
    // function useWindowSize() {
    //   const [size, setSize] = useState([0, 0]);
    //   useLayoutEffect(() => {
    //     function updateSize() {
    //       setSize([window.innerWidth, window.innerHeight]);
    //     }
    //     window.addEventListener('resize', updateSize);
    //     updateSize();
    //     return () => window.removeEventListener('resize', updateSize);
    //   }, []);
    //   console.log(size)
    //   return size;
    // }
    // const [width, height] = useWindowSize();

    
    // useEffect(() => {
    //     if (init) 
    //         if (document.documentElement.clientWidth<700) addPadding()
    //     else
    //         setInit(true)
    // }, [init])

    return (
        <div>
            {/* <Contacts/> */}
            <Intro/>
            <Consulting_Type/>
            <Situations/>
            <Citition/>
            <Reviews/>
            <Certificates/>
            <Video/>
            <Articles/>
            <Neuro/>
            <Footer/>
        </div>
    )
}

export default Main