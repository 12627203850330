import React, { useEffect, useRef, useState } from "react";
import Request_Form from "./request_form";
import { url, short_3k_url } from "../App";
import Footer from "./footer";
import Image1 from "./3.jpg"


const About = () => {
    const [loading, setLoading] = useState(true)
    let param_dict = {0: ['5vw', '0vw'], 1: ['-14.2vw', '5.5vw'], 2: ['-33.4vw', '11.2vw'], 3: ['-55.6vw', '16.5vw']}

    let subblock1_style
    let li_style
    let ul_style
    if (document.documentElement.clientWidth>=750) {
        subblock1_style = {height: "fit-content", marginTop: "20vh", padding: "2vw", marginLeft: "5vw"}
    } else {
        subblock1_style = {height: "fit-content", marginTop: "25vh", padding: "2vw", marginLeft: "5vw", width: "84vw"}
    }
    let subblock2_style = JSON.parse(JSON.stringify(subblock1_style))
    let subblock3_style = JSON.parse(JSON.stringify(subblock1_style))
    // subblock2_style['marginTop'] = '25vh'
    subblock2_style['position'] = 'unset'
    subblock3_style['marginLeft'] = '-1vw'

    if (document.documentElement.clientWidth>=750) {
        subblock2_style['marginLeft'] = '50vw'
        subblock2_style['width'] = '40vw'
        subblock3_style['marginTop'] = '30vh'
        subblock3_style['width'] = '80vw'
    } 
    li_style = {textAlign: "justify", textIndent: "5vw"}
    ul_style = {paddingLeft: "2vw", paddingRight: "2vw"}
    const reqImgs = require.context ( './cards', true, /\.(png|jpe?g|svg)$/ )
    const imgList = reqImgs.keys()
    const imageDict = Object.fromEntries( reqImgs.keys().map( image => [image, reqImgs(image)]) )

    function click(index) {
        const elem = document.getElementById(index)
        elem.parentNode.insertBefore(elem, elem.parentNode.firstChild)
    }

    useEffect(() => {
        const img = document.getElementsByClassName('about-block-2-main-img')[0]
        let height = window.getComputedStyle(img)['height']
        const vh = window.innerHeight / 100
        if (height === '0px') 
            setLoading(!loading)
        else {
            const block_height = parseFloat(height.slice(0, -2)) + 10*vh

            const block_1 = document.getElementsByClassName('subblock-1')[0]
            console.log(document.getElementsByClassName('subblock-1')[0].getBoundingClientRect()['height'])


            const block_2 = document.getElementsByClassName('about-row')[0]
            const text = document.getElementsByClassName('about-block-2')[0]
            block_2.style['height'] = block_height + "px"
            if (document.documentElement.clientWidth>=750) {
                text.style['top'] = 85*vh + (document.getElementsByClassName('about-block-2-main-img')[0].parentElement.getBoundingClientRect()['height'] - text.getBoundingClientRect()['height'])/2 + 'px'
            } else {
                block_1.style.marginTop = 2*vh + (document.getElementsByClassName('about-block-1')[0].getBoundingClientRect()['height'] - document.getElementsByClassName('subblock-1')[0].getBoundingClientRect()['height']) / 2 + 'px'
                img.style['marginTop'] = '2vh'
                text.firstChild.firstChild.style['fontSize'] = "calc(.7vw + .6vh)"
                text.style['top'] = 80*vh + (document.getElementsByClassName('about-block-2-main-img')[0].parentElement.getBoundingClientRect()['height'] - text.getBoundingClientRect()['height'])/2 + 'px'
            }
        }
    }, [loading])

    return (
        <div>
            <Request_Form/>
            <div className="about-container">
                <div className="block-1 about-block-1">
                    <div className="subblock-1 galo" style={subblock1_style}>
                    <p className="white-text small-text">Образование – высшее, закончила Орехово-Зуевский педагогический институт в 1998 году по специальности практический психолог</p>
                    <p className="white-text small-text">Стаж работы – 25 лет</p>
                    <p className="white-text small-text">Стаж работы в государственном казенном учреждении социального обслуживания Московской области «Сергиево-Посадский социально-реабилитационный центр для несовершеннолетних» - 20 лет</p>

                    </div>
                </div>
                {/* <div className="about-block-2"> */}
                {/* <div style={{display: "flex", flexDirection: "column"}}> */}
                    <div className="about-row">
                        <div style={{width: "42.5vw"}}>
                        {imgList.map( (img, index) => 
                            <button id={index} onClick={() => click(index)} className="about-block-2-sub-img" ><img src={imageDict[img]} alt=""/></button>)}
                        </div>
                        <div style={{width: "42.5vw"}}>
                            <img src={Image1} className="about-block-2-main-img" alt=""/>
                        </div>
                </div>
                    <div className="about-block-2">
                        <div className="galo">
                            <p className="about-text" >Повышала квалификацию по направлениям: песочная терапия, профилактика употребления ПАВ, психодрама и ролевые игры в образовательной психотерапии, арт-терапия, семейная системная психотерапия, детская интегративная психотерапия, «Сеть социальных контактов», психология в социальной сфере, подготовка региональных экспертов конкурсов профессионального мастерства «Абилимпикс», социальная работа с семьями в кризисных ситуациях, развитие родительской компетенции в рамках тренинга родительской уверенности, оказание психологической помощи семьям в преодолении острых кризисных ситуаций, краткосрочное кризисное консультирование семьи, групповая психологическая работа с родителями, склонными к агрессивному поведению, эмоционально-образная психотерапия</p>
                        </div>
                    </div>
                {/* </div> */}
                <div className="block-1 about-block-3">
                    <div className="galo"  style={subblock2_style}>
                        <p className="white-text small-text">Работа в Семейном центре многогранна, подразумевает взаимодействие с различными проблемами семей и детей группы риска, требует индивидуального подхода, поэтому в моей работе использую различные направления и методы, такие как психодраму, песочную терапию, арт-терапию, сказкотерапию, игровую терапию, эмоционально-образную терапию, интегративный подход</p>
                    </div>
                </div>
                
                <div className="block-1 about-block-4">
                    <div className="galo" style={subblock3_style}>
                        <p className="about-text">Моя деятельность в качестве психолога осуществляется на основе комплексной реабилитационной работы с ребенком и с семьей:</p>
                        <ul className="about-text" style={ul_style}>
                            <li style={li_style}>1)ㅤПсихологическую диагностику детей с целью дальнейшей разработки индивидуальных и групповых программ социальной реабилитации, осуществления контроля за их реализацией</li>
                            <li style={li_style}>2)ㅤРазработку и реализацию индивидуальных программ социальной реабилитации несовершеннолетних</li>
                            <li style={li_style}>3)ㅤПсихологическое консультирование семей, несовершеннолетних, родителей, оказавшихся в трудной жизненной ситуации</li>
                            <li style={li_style}>4)ㅤРазработку и осуществление групповых программ</li>
                            <li style={li_style}>5)ㅤОрганизацию и участие в социально-медико-психолого-педагогическом консилиуме, решающего в комплексе проблемы конкретного ребенка и семьи</li>
                        </ul>
                    </div>
                </div>
                </div>
                <Footer/>
            </div>
    )
}
                    {/* <a href="/" className="btn-1 blank_link" >На главную страницу</a> */}

export default About